<template>
    <div class="modal" v-if="show" :class="{ active : show }">
        <div class="modal_container">
            <div class="modal_header border-bottom">
                <div class="modal_info">
                    <h1>Announcements</h1>
                    <button type="button" :disabled="announcementSettingAddLoader" class="add_btn" @click="addAnnouncement(clientPortal.id)" v-if="tab === 'active'">
                        <i class="fa fa-spin fa-spinner" v-if="announcementSettingAddLoader"></i>
                        {{ announcementSettingUpdateLoader ? 'Adding' : '+ Add Announcement' }}
                    </button>
                </div>
                <ul class="tab_sec">
                    <li @click="tab = 'active'" :class="{'active' : tab === 'active'}">Active</li>
                    <li  @click="tab = 'archive'" :class="{'active' : tab === 'archive'}">Archived</li>
                </ul>
                <button class="close_btn left_out" @click="closeDrawer()"><i class="fas fa-long-arrow-alt-right"></i></button>
            </div>
            <div class="modal_body">
                <div class="announcement_box" v-if="tab === 'active'">
                    <div class="announcement_item">
                        <div class="date_time">
                            <div class="mr-auto">{{moment.utc(defaultAnnouncement.created_at).tz(user && user.timezone ? user.timezone : 'UTC').format('ll')}}</div>
                            <div><span class="tag">{{moment.utc(defaultAnnouncement.created_at).tz(user && user.timezone ? user.timezone : 'UTC').format('hh:mm A')}}</span>{{ user.timezone ? user.timezone : 'UTC' }}</div>
                            <div class="options" @click.stop="toggleOption($event)" v-click-outside="closeAllToggleOption">
                                <i class="fas fa-ellipsis-v"></i>
                                <div class="dropdown_wpr">
                                    <ul>
                                        <li @click="handlePin(defaultAnnouncement.is_pinned, defaultAnnouncement.id)">
                                            {{ defaultAnnouncement && defaultAnnouncement.is_pinned ? 'Unpin' : 'Pin' }}
                                        </li>
                                        <li v-if="announcements.length >= 1" @click="handleArchiveGuide(defaultAnnouncement.is_archived, defaultAnnouncement.id)">
                                            Archive
                                        </li>
                                        <li v-if="announcements.length >= 1" class="danger" @click="handleDeletePrompt(defaultAnnouncement.id)">Delete</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="announcement_text" :style="`background: ${defaultAnnouncement.bg_color};`">
                            <div class="saved_wpr">
                                <span class="pin" v-if="defaultAnnouncement.is_pinned"><i class="fas fa-thumbtack"></i></span>
                                <div class="action_list">
                                    <span class="btn edit" @click="handleEditAnnouncement($event)"><i class="fas fa-pen"></i></span>
                                </div>
                                <span class="tag live" v-if="defaultAnnouncement.is_published">Live</span>
                                <p :style="`color: ${clientPortal.announcement_text_color ? defaultAnnouncement.text_color : '#000'};`" v-html="defaultAnnouncement.message"></p>
                                <div class="cta_btn" v-if="defaultAnnouncement.has_include_link">
                                    <a :href="defaultAnnouncement.link_address" target="_blank" :style="`color: ${defaultAnnouncement.link_color}`">{{ defaultAnnouncement.link_text }}</a>
                                </div>
                            </div>
                            <div class="rename_fld">
                                <redactor v-model="defaultAnnouncement.message" name="announcement_message" ref="step-editor" :reset-to-defaut-watcher="announcementMessageDefaultContentWatcher" :email-editor="false"/>
                                <div class="capsule_elm mt-3">
                                    <h5 class="large">Include Link</h5>
                                    <label  class="switch_option capsule_btn">
                                        <input type="checkbox" :true-value="1" :false-value="0" v-model="defaultAnnouncement.has_include_link" hidden>
                                        <div><span></span></div>
                                    </label>
                                </div>
                                <template v-if="defaultAnnouncement.has_include_link">
                                    <div class="field_wpr">
                                        <input type="text" placeholder="Learn More" v-model="defaultAnnouncement.link_text">
                                    </div>
                                    <div class="field_wpr has_prefix">
                                        <input type="text" placeholder="Learn More" v-model="defaultAnnouncement.link_address">
                                        <span class="prefix">URL</span>
                                    </div>
                                    <div class="mt-3">
                                        <color-picker v-model="defaultAnnouncement.link_color"/>
                                    </div>
                                    <div class="capsule_elm mt-3">
                                        <h5 class="large">Notify Clients</h5>
                                        <label :for="`notification_${defaultAnnouncement.id}`" class="switch_option capsule_btn">
                                            <input type="checkbox" :id="`notification_${defaultAnnouncement.id}`" v-model="defaultAnnouncement.has_notification" :true-value="1" :false-value="0" hidden>
                                            <div><span></span></div>
                                        </label>
                                    </div>
                                </template>
                                <div class="action_wpr">
                                    <div class="capsule_elm mr-auto">
                                        <label :for="`has_published_${defaultAnnouncement.id}`" class="switch_option capsule_btn py-1">
                                            <input type="checkbox" :id="`has_published_${defaultAnnouncement.id}`" v-model="defaultAnnouncement.is_published" :true-value="1" :false-value="0" hidden>
                                            <div><span></span></div>
                                        </label>
                                        <span class="stat live">Live</span>
                                    </div>
                                    <button type="button" :disabled="announcementSettingUpdateLoader" class="btn cancel_btn bg-light" @click="handleEditAnnouncement($event)">Cancel</button>
                                    <button type="button" :disabled="announcementSettingUpdateLoader" class="btn save_btn" @click="handleEditAnnouncement($event, defaultAnnouncement)">
                                        <i class="fa fa-spin fa-spinner" v-if="announcementSettingUpdateLoader"></i>
                                        {{ announcementSettingUpdateLoader ? 'Saving' : 'Save' }}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <draggable v-model="announcements" class="announcement_box" item-key="id" :animation="200" @end="handleAnnouncementSort" handle=".handle-drag">
                        <template #item="{ element: announcement, index: a }">
                            <div class="announcement_item" :key="a">
                                <div class="date_time">
                                    <i class="fas fa-arrows-alt mr-2 handle-drag"></i>
                                    <div class="mr-auto">{{moment.utc(announcement.created_at).tz(user && user.timezone ? user.timezone : 'UTC').format('ll')}}</div>
                                    <div><span class="tag">{{moment.utc(announcement.created_at).tz(user && user.timezone ? user.timezone : 'UTC').format('hh:mm A')}}</span>{{ user.timezone ? user.timezone : 'UTC' }}</div>
                                    <div class="options" @click.stop="toggleOption($event)" v-click-outside="closeAllToggleOption">
                                        <i class="fas fa-ellipsis-v"></i>
                                        <div class="dropdown_wpr">
                                            <ul>
                                                <li @click="handlePin(announcement.is_pinned, announcement.id)">
                                                    {{ announcement && announcement.is_pinned ? 'Unpin' : 'Pin' }}
                                                </li>
                                                <li v-if="announcements.length >= 1" @click="handleArchiveGuide(announcement.is_archived, announcement.id)">
                                                    Archive
                                                </li>
                                                <li class="danger" v-if="announcements.length >= 1" @click="handleDeletePrompt(announcement.id)">Delete</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="announcement_text" :style="`background: ${announcement.bg_color};`">
                                    <div class="saved_wpr">
                                        <span class="pin" v-if="announcement.is_pinned"><i class="fas fa-thumbtack"></i></span>
                                        <div class="action_list">
                                            <span class="btn edit" @click="handleEditAnnouncement($event)"><i class="fas fa-pen"></i></span>
                                        </div>
                                        <span class="tag live" v-if="announcement.is_published">Live</span>
                                        <p :style="`color: ${clientPortal.announcement_text_color ? announcement.text_color : '#000'};`" v-html="announcement.message"></p>
                                        <div class="cta_btn" v-if="announcement.has_include_link">
                                            <a :href="announcement.link_address" target="_blank" :style="`color: ${announcement.link_color}`">{{ announcement.link_text }}</a>
                                        </div>
                                    </div>
                                    <div class="rename_fld">
                                        <redactor v-model="announcement.message" name="announcement_message" ref="step-editor" :reset-to-defaut-watcher="announcementMessageDefaultContentWatcher" :email-editor="false"/>
                                        <div class="capsule_elm mt-3">
                                            <h5 class="large">Include Link</h5>
                                            <label  class="switch_option capsule_btn">
                                                <input type="checkbox" :true-value="1" :false-value="0" v-model="announcement.has_include_link" hidden>
                                                <div><span></span></div>
                                            </label>
                                        </div>
                                        <template v-if="announcement.has_include_link">
                                            <div class="field_wpr">
                                                <input type="text" placeholder="Learn More" v-model="announcement.link_text">
                                            </div>
                                            <div class="field_wpr has_prefix">
                                                <input type="text" placeholder="Learn More" v-model="announcement.link_address">
                                                <span class="prefix">URL</span>
                                            </div>
                                            <div class="mt-3">
                                                <color-picker v-model="announcement.link_color"/>
                                            </div>
                                            <div class="capsule_elm mt-3">
                                                <h5 class="large">Notify Clients</h5>
                                                <label :for="`is_notification_${announcement.id}`" class="switch_option capsule_btn">
                                                    <input type="checkbox" :id="`is_notification_${announcement.id}`" v-model="announcement.has_notification" :true-value="1" :false-value="0" hidden>
                                                    <div><span></span></div>
                                                </label>
                                            </div>
                                        </template>
                                        <div class="action_wpr">
                                            <div class="capsule_elm mr-auto">
                                                <label :for="`published_${announcement.id}`" class="switch_option capsule_btn py-1">
                                                    <input type="checkbox" :id="`published_${announcement.id}`" v-model="announcement.is_published" :true-value="1" :false-value="0" hidden>
                                                    <div><span></span></div>
                                                </label>
                                                <span class="stat live">Live</span>
                                            </div>
                                            <button type="button" :disabled="announcementSettingUpdateLoader" class="btn cancel_btn bg-light" @click="handleEditAnnouncement($event)">Cancel</button>
                                            <button type="button" :disabled="announcementSettingUpdateLoader" class="btn save_btn" @click="handleEditAnnouncement($event, announcement)">
                                                <i class="fa fa-spin fa-spinner" v-if="announcementSettingUpdateLoader"></i>
                                                {{ announcementSettingUpdateLoader ? 'Saving' : 'Save' }}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </draggable>
                </div>
                <div class="announcement_box" v-if="tab === 'archive'">
                    <div class="announcement_item" v-for="(archive, a) of archiveAnnouncements" :key="a">
                        <div class="date_time">
                            <div class="mr-auto">{{moment.utc(archive.created_at).tz(user && user.timezone ? user.timezone : 'UTC').format('ll')}}</div>
                            <div><span class="tag">{{moment.utc(archive.created_at).tz(user && user.timezone ? user.timezone : 'UTC').format('hh:mm A')}}</span>{{ user.timezone ? user.timezone : 'UTC' }}</div>
                            <div class="options" @click.stop="toggleOption($event)" v-click-outside="closeAllToggleOption">
                                <i class="fas fa-ellipsis-v"></i>
                                <div class="dropdown_wpr">
                                    <ul>
                                        <li @click="handleEditAnnouncement($event)" :data-target="`edit-archive-${ archive.id }`">Edit</li>
                                        <li @click="handleRestoreArchiveGuide(archive.is_archived, archive.id)">Restore</li>
                                        <li class="danger" @click="handleDeletePrompt(archive.id)">Delete</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="announcement_text" :id="`edit-archive-${ archive.id }`" :style="`background: ${archive.bg_color};`">
                            <div class="saved_wpr">
                                <p :style="`color: ${archive.text_color ? archive.text_color : '#000'};`" v-html="archive.message"></p>
                                <div class="cta_btn" v-if="archive.has_include_link">
                                    <a :href="archive.link_address" target="_blank" :style="`color: ${archive.link_color}`">{{ archive.link_text }}</a>
                                </div>
                            </div>
                            <div class="rename_fld">
                                <redactor v-model="archive.message" name="announcement_message" :default-value="archive.message" ref="step-editor" :reset-to-defaut-watcher="announcementMessageDefaultContentWatcher" :email-editor="false"/>
                                <div class="capsule_elm mt-3">
                                    <h5 class="large">Include Link</h5>
                                    <label for="announcement-link" class="switch_option capsule_btn">
                                        <input type="checkbox" id="announcement-link" :true-value="1" :false-value="0" v-model="archive.has_include_link" hidden>
                                        <div><span></span></div>
                                    </label>
                                </div>
                                <template v-if="archive.has_include_link">
                                    <div class="field_wpr">
                                        <input type="text" placeholder="Learn More" v-model="archive.link_text">
                                    </div>
                                    <div class="field_wpr has_prefix">
                                        <input type="text" placeholder="Learn More" v-model="archive.link_address">
                                        <span class="prefix">URL</span>
                                    </div>
                                    <div class="mt-3">
                                        <color-picker v-model="archive.link_color"/>
                                    </div>
                                    <div class="capsule_elm mt-3">
                                        <h5 class="large">Notify Clients</h5>
                                        <label :for="`has_notification${a}`" class="switch_option capsule_btn">
                                            <input type="checkbox" :id="`has_notification${a}`" :true-value="1" :false-value="0" hidden>
                                            <div><span></span></div>
                                        </label>
                                    </div>
                                </template>
                                <div class="action_wpr">
                                    <button type="button" :disabled="announcementSettingUpdateLoader" class="btn cancel_btn bg-light" @click="handleEditAnnouncement($event)">Cancel</button>
                                    <button type="button" :disabled="announcementSettingUpdateLoader" class="btn save_btn" @click="handleEditAnnouncement($event, archive)">
                                        <i class="fa fa-spin fa-spinner" v-if="announcementSettingUpdateLoader"></i>
                                        {{ announcementSettingUpdateLoader ? 'Saving' : 'Save' }}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="empty_box" v-if="archiveAnnouncements.length == 0">
                        <img src="@/assets/images/empty_state.svg" alt="Empty">
                        <h4 class="text-center">No Records Found.</h4>
                    </div>
                </div>
            </div>
            <div class="modal_footer">
                <button type="button" :disabled="announcementSettingUpdateLoader" class="btn cancel_btn bg-light" @click="handleEditAnnouncement($event)">Cancel</button>
                <!-- <button type="button" class="btn save_btn" @click="closeDrawer()">Save</button> -->
            </div>
        </div>
    </div>
    <div class="modal secondary prompt" v-if="deletePrompt">
        <div class="modal_container">
            <img src="@/assets/images/alert.svg" alt="" class="logo_img">
            <h5>This action is pernmanent. Would you rather archive this message?</h5>
            <div class="action_wpr mt-3">
                <button type="button" class="btn cancel_btn" @click="archiveAnnouncement()">Yes</button>
                <button type="button" class="btn save_btn" @click="deleteAnnouncement()">No, Delete it</button>
            </div>
        </div>
    </div>
    <div class="modal secondary prompt" v-if="archivePrompt">
        <div class="modal_container">
            <img src="@/assets/images/alert.svg" alt="" class="logo_img">
            <h5>This message has been archived. To see archived messages click "Archive" tab.</h5>
            <label for="disable_archive_guide" class="checkbox">
                <input type="checkbox" id="disable_archive_guide" @input="$emit('update:archiveGuide', $event.target.value)" :true-value="1" :false-value="0" hidden>
                <span><i class="fas fa-check"></i></span> Don't show it again.
            </label>
            <div class="action_wpr mt-3">
                <button type="button" class="btn save_btn" @click="archivePrompt = false;">Okay</button>
            </div>
        </div>
    </div>
    <div class="modal secondary prompt" v-if="notificationPrompt">
        <div class="modal_container">
            <img src="@/assets/images/alert.svg" alt="" class="logo_img">
            <h5>Once your announcement is published, we'll notify your clients one-time via the portal chat widget. It will remain visible in your announcement settings until you delete, unpublish, or archive it.</h5>
            <label for="disable_notification_guide" class="checkbox">
                <input type="checkbox" id="disable_notification_guide" @input="$emit('update:notificationGuide', $event.target.value)" :true-value="1" :false-value="0" hidden>
                <span><i class="fas fa-check"></i></span> Don't show it again.
            </label>
            <div class="action_wpr mt-3">
                <button type="button" class="btn save_btn" @click="notificationPrompt = false;">Okay</button>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState, mapActions } from 'vuex'
    import moment from 'moment'
    import Draggable from 'vuedraggable'

    export default {
        data(){
            return{
                tab: 'active',
                moment,
                deletePrompt: false,
                promptId: 0,
                archivePrompt: false,
                notificationPrompt: false,
                announcements: [],
                defaultAnnouncement: {},
                archiveAnnouncements:[],
                announcementMessageDefaultContentWatcher: 0
            }
        },

        components:{
            Draggable,
        },

        props: {
            show: Boolean,
            archiveGuide: Number,
            notificationGuide: Number,
        },

        emits: ['update:show', 'update:archiveGuide', 'update:notificationGuide'],

        watch:{
            showAnnouncementList (val) {
                if (val == true) {
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },

            deletePrompt (val) {
                if (val == true) {
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },

            clientPortal: {
                handler (clientPortal) {
                    const vm = this;
                    const announcements = vm.clientPortal.announcement_settings ? JSON.parse(JSON.stringify(clientPortal.announcement_settings)) : [];

                    if (announcements.length > 1) {
                        vm.defaultAnnouncement  = announcements.shift();
                        vm.announcements        = announcements;
                    } else {
                        vm.defaultAnnouncement  = announcements[0];
                        vm.announcements        = [];
                    }

                    vm.archiveAnnouncements = vm.clientPortal.archive_announcements ? JSON.parse(JSON.stringify(vm.clientPortal.archive_announcements)) : [];

                    setTimeout(() => {
                        vm.announcementMessageDefaultContentWatcher++;
                    }, 1000);
                },
                deep: true,
            }
        },
 
            computed: mapState({
            user: state => state.authModule.user,
            clientPortal: state => state.clientPortalModule.clientPortal,
            announcementSettingAddLoader: state => state.clientPortalModule.announcementSettingAddLoader,
            announcementSettingUpdateLoader: state => state.clientPortalModule.announcementSettingUpdateLoader,
        }),

        mounted () {
            const vm = this;

            const announcements = vm.clientPortal.announcement_settings ? JSON.parse(JSON.stringify(vm.clientPortal.announcement_settings)) : [];

            if (announcements.length > 1) {
                vm.defaultAnnouncement  = announcements.shift();
                vm.announcements        = announcements;
            } else {
                vm.defaultAnnouncement  = announcements[0];
                vm.announcements        = [];
            }

            vm.archiveAnnouncements = vm.clientPortal.announcement_settings ? JSON.parse(JSON.stringify(vm.clientPortal.archive_announcements)) : [];

            setTimeout(() => {
                vm.announcementMessageDefaultContentWatcher++;
            }, 1000);
        },


        methods: {
            ...mapActions({
                addAnnouncement: 'clientPortalModule/addAnnouncement',
                updateAnnouncementSetting: 'clientPortalModule/updateAnnouncementSetting',
                deleteAnnouncementSetting: 'clientPortalModule/deleteAnnouncementSetting',
                sortAnnouncementSetting: 'clientPortalModule/sortAnnouncementSetting',
            }),

            closeDrawer () {
                this.$emit('update:show', false);
            },

            handleEditAnnouncement (ev, params = null) {
                const vm = this;
                var elm = null;

                if (ev.currentTarget.getAttribute('data-target')) {
                    const _target = ev.currentTarget.getAttribute('data-target');
                    elm = document.getElementById(_target);
                } else {
                    elm = ev.currentTarget.closest('.announcement_text');
                }

                if (params) {
                    vm.updateAnnouncementSetting(params).then((result) => {
                        if (result) {
                            if (elm.classList.contains('editable')) {
                                elm.classList.remove('editable');
                            } else{
                                elm.classList.add('editable');
                            }
                        }
                    })
                } else {
                    if (elm.classList.contains('editable')) {
                        elm.classList.remove('editable');
                    } else{
                        elm.classList.add('editable');
                    }

                    setTimeout(() => {
                        vm.announcementMessageDefaultContentWatcher++;
                    }, 1000);
                }
            },

            handleDeletePrompt (id) {
                const vm = this;
                vm.deletePrompt = true;
                vm.promptId = id;
            },

            deleteAnnouncement () {
                const vm = this;

                vm.form = {
                    id: vm.promptId,
                };
                vm.deletePrompt = false;
                const params = JSON.parse(JSON.stringify(vm.form));

                vm.deleteAnnouncementSetting(params);
            },

            archiveAnnouncement () {
                const vm = this;

                vm.form = {
                    id: vm.promptId,
                    is_archived: 1,
                };

                vm.deletePrompt = false;
                const params = JSON.parse(JSON.stringify(vm.form));

                vm.updateAnnouncementSetting(params);
            },

            handleArchiveGuide (value, id) {
                const vm = this;

                if (!vm.archiveGuide) {
                    vm.archivePrompt = true;
                }

                vm.form = {
                    id: id,
                    is_archived: !value,
                };
                const params = JSON.parse(JSON.stringify(vm.form));

                vm.updateAnnouncementSetting(params);
            },

            handleRestoreArchiveGuide (value, id){
                const vm = this;

                vm.form = {
                    id: id,
                    is_archived: !value,
                };
                const params = JSON.parse(JSON.stringify(vm.form));

                vm.updateAnnouncementSetting(params);
            },

            handleNotificationGuide () {
                const vm = this;

                if (!vm.notificationGuide) {
                    vm.notificationPrompt = true;
                }
            },

            handleEdit (ev) {
                const elm = ev.currentTarget.closest('.announcement_text');
                
                if(elm.classList.contains('editable')){
                    elm.classList.remove('editable');
                } else{
                    elm.classList.add('editable');
                }
            },

            toggleOption(ev){
                const vm = this;
                let elm = ev.currentTarget;
                let targetElm = elm.closest('.options').querySelector('.dropdown_wpr');
                
                if(targetElm.classList.contains('active')){
                    targetElm.classList.remove('active');
                } else{
                    vm.closeAllToggleOption();
                    targetElm.classList.add('active');
                }
            },

            closeAllToggleOption(){
                let allTargetElm = document.querySelectorAll('.announcement_item .options .dropdown_wpr');
                allTargetElm.forEach(function(item){
                    if(item.classList.contains('active')){
                        item.classList.remove('active');
                    }
                });
            },

            handlePin (value, id) {
                const vm = this;
                vm.form = {
                    id: id,
                    is_pinned: !value,
                };
                const params = JSON.parse(JSON.stringify(vm.form));

                vm.updateAnnouncementSetting(params);
            },

            handleAnnouncementSort () {
                const vm = this;

                const ids = vm.announcements.map(announcement => announcement.id);

                vm.sortAnnouncementSetting({ ids });
            },
        }
    }
</script>

<style scoped>
.modal .modal_body{
    background: #fff;
}
.modal_header .add_btn{
    margin: 5px 0 5px auto;
    font-weight: 400;
}
.modal_header .tab_sec {
    margin: 0;
    padding: 0;
    gap: 0;
    list-style-type: none;
    display: flex;
    justify-content: flex-start;
    border: 0;
}
.modal_header .tab_sec li {
    font-size: 13px;
    line-height: 18px;
    font-weight: 400;
    color: #121525;
    padding: 0 0 12px 0;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    margin: 0 25px -1px 0;
}
.modal_header .tab_sec li:after {
    position: absolute;
    content: "";
    left: -100%;
    bottom: 0;
    width: 100%;
    border-bottom: 1px solid var(--var-portal-tab-background-color);
    transition: all .3s ease-in-out;
}
.modal_header .tab_sec li.active{
    background: transparent;
    color: var(--var-portal-tab-background-color);
}
.modal_header .tab_sec li.active:after{
    left: 0;
}
/* .sticky_bar{
    position: sticky;
    top: -30px;
    left: 0;
    margin-top: -30px;
    padding: 20px 0;
    background: #fff;
    z-index: 2;
    display: flex;
}
.sticky_bar .add_btn{
    margin: 0 0 0 auto;
    font-size: 13px;
} */
.announcement_box .announcement_item{
    margin-bottom: 40px;
}
.announcement_box .date_time{
    font-size: 11px;
    line-height: 14px;
    font-weight: 500;
    color: #5a5a5a;
    padding-bottom: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.announcement_box .date_time .handle-drag{
    cursor: move;
}
.announcement_box .date_time .options{
    position: relative;
    margin-left: 15px;
    color: #121525;
    font-weight: 400;
    cursor: pointer;
}
.announcement_box .date_time .options .dropdown_wpr{
    left: auto;
    right: 0;
}
.announcement_box .date_time .options .dropdown_wpr:before{
    display: none;
}
.announcement_box .tag{
    background: #e9e9e9;
    color: #5a5a5a;
    padding: 3px 8px;
    margin-right: 6px;
    border-radius: 10px;
    font-size: 10px;
    line-height: 12px;
    font-weight: 500;
    text-transform: uppercase;
}
.announcement_box .announcement_text{
    width: 100%;
    padding: 40px 20px 20px 20px;
    border-radius: 8px;
    position: relative;
}
.announcement_box .announcement_text p{
    font-size: 15px;
    line-height: 27px;
    font-weight: 400;
    color: #5a5a5a;
    min-height: 27px;
}
.announcement_box .announcement_text p :deep(p:empty){
    min-height: 27px;
}
.announcement_box .announcement_text .cta_btn{
    display: flex;
    justify-content: flex-end;
    margin-top: 15px;
    padding-top: 15px;
    border-top: 1px solid var(--var-announcement-text-color20);
}
.announcement_box .announcement_text .cta_btn a{
    font-size: 13px;
    line-height: 16px;
    font-weight: 500;
    text-decoration: none;
    cursor: pointer;
}
.announcement_box .announcement_text .cta_btn a:not(:last-child){
    border-right: 1px solid #cfcfcf;
}
.announcement_box .announcement_text .archive_btn{
    font-size: 13px;
    line-height: 16px;
    font-weight: 500;
    color: #8a8a8a;
}
.announcement_box .announcement_text .action_list{
    position: absolute;
    right: -5px;
    top: -5px;
    display: flex;
    flex-direction: column;
    opacity: 0;
    transition: all 0.3s ease-in-out;
    z-index: 2;
}
.announcement_box .announcement_text .action_list .btn{
    width: 22px;
    height: 22px;
    flex-shrink: 0;
    font-size: 10px;
    line-height: 20px;
    display: block;
    text-align: center;
    border: 1px solid #d9d9d9;
    background: #fff;
    color: #5a5a5a;
    cursor: pointer;
    border-radius: 50%;
    margin: 0 0 7px 0;
}
.announcement_box .announcement_text .pin{
    width: 22px;
    height: 22px;
    flex-shrink: 0;
    position: absolute;
    left: 50%;
    top: -8px;
    transform: translateX(-50%);
    font-size: 10px;
    line-height: 20px;
    display: block;
    text-align: center;
    border: 1px solid #d9d9d9;
    background: #fff;
    color: #121525;
    cursor: pointer;
    border-radius: 50%;
    transform: rotate(30deg);
}
.announcement_box .announcement_text .tag{
    position: absolute;
    left: 20px;
    top: 15px;
    background: #fff;
    font-size: 11px;
    color: #2f7eed;
    text-transform: capitalize;
}
.announcement_box .announcement_text .tag.live{
    color: #14af03;
}
.announcement_box .announcement_text:hover .action_list{
    opacity: 1;
}
.announcement_box .announcement_text .rename_fld .field_wpr{
    margin-top: 15px;
}
.announcement_box .announcement_text .rename_fld :deep(.color-picker .swatches){
    background: #fff;
    border-radius: 5px;
    padding: 10px;
    margin: 0;
}
.announcement_box .announcement_text .rename_fld .action_wpr{
    margin: 15px 0 5px 0;
}
.announcement_box .announcement_text .rename_fld,
.announcement_box .announcement_text.editable .saved_wpr{
    display: none;
}
.announcement_box .announcement_text.editable .rename_fld,
.announcement_box .announcement_text .saved_wpr{
    display: block;
}
/* .announcement_box .announcement_text .rename_fld :deep(.redactor-toolbar-wrapper){
    top: 27px !important;
} */
.announcement_box .announcement_text .rename_fld :deep(.redactor-box.redactor-styles-on .redactor-toolbar) {
    padding: 10px !important;
}
.announcement_box .announcement_text .rename_fld :deep(.redactor-toolbar a.re-button-icon, .redactor-air a.re-button-icon) {
    font-size: 12px;
    padding: 5px !important;
    margin-right: 4px !important;
}
.announcement_box .announcement_text .rename_fld :deep(.redactor-box .redactor-styles){
    resize: vertical;
}
.announcement_box .announcement_text .action_wpr .btn{
    padding: 0 10px;
    font-size: 11px;
    min-width: 70px;
    height: 30px;
}
.announcement_box .announcement_text .action_wpr .stat{
    background: #fff;
    border-radius: 10px;
    padding: 2px 6px;
    margin-left: 7px;
    font-size: 11px;
    line-height: 14px;
    color: #999;
    font-weight: 500;
}
.announcement_box .announcement_text .action_wpr .stat.live{
    color: #14af03;
}
.announcement_box .announcement_text .action_wpr .btn:first-of-type{
    margin-left: 0;
}
.empty_box {
    padding: 30px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    background: #f9f9f9;
    border-radius: 5px;
}
.empty_box img {
    max-height: 70px;
    width: auto;
    margin-bottom: 20px;
}
.empty_box h4 {
    font-size: 15px;
    line-height: 22px;
    font-weight: 400;
    color: #2c3e50;
}
.modal.prompt h5{
    max-width: 425px;
}
.modal.prompt .close_btn {
    position: absolute;
    right: -7px;
    left: auto;
    top: -7px;
    border: 1px solid #c7c7c7;
    background: rgba(50, 55, 59, 0.7);
    color: #fff;
    border-radius: 50%;
    font-size: 11px;
    width: 22px;
    height: 22px;
    cursor: pointer;
    z-index: 1;
}
.modal.prompt .logo_img{
    max-height: 90px;
    width: auto;
    margin-bottom: 30px;
}
.modal.prompt label.checkbox{
    justify-content: flex-start;
    align-items: center;
    font-size: 15px;
    line-height: 18px;
    font-weight: 400;
    color: #121525;
    margin: 10px 0;
}
.modal.prompt label.checkbox span{
    margin-right: 10px;
}

@media(max-width: 768px){
    .announcement_box .announcement_text p,
    .announcement_box .announcement_text p :deep(p:empty){
        min-height: 20px;
    }
    .announcement_box .announcement_text .action_wpr .btn{
        line-height: 28px;
    }
}
</style>
